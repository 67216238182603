import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { InputText } from '~/components/NovosInputs';
import { useModalDeCorrecoes } from '../../ModalDeCorrecoesContext';
import { ConfirmButton } from '~/components/Buttons';
import { useValidadorXML } from '~/pages/ValidadorXML/ValidadorContext';
import { toast } from 'react-toastify';
import { ResponseDataProps } from '../../protocols';
import {
  atualizaCodigoGtin,
  deletaCodigoGtin,
} from '~/pages/ValidadorXML/Funcoes/Correcao';
import api from '~/services/api';

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { MdDeleteForever } from 'react-icons/md';
import { Spinner } from 'react-bootstrap';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

/**
 * @param CodigoDeBarras Aqui serão atualizados os códigos GTIN do item (adicionados ou removidos)
 */

export const CodigoDeBarras: React.FC = () => {
  const {
    dadosItemACorrigir,
    setDadosItemACorrigir,
    setListaDeStatus,
    executando,
    setExecutando,
    listaDeGtins,
    setListaDeGtins,
    codigoDeBarra,
    setCodigoDeBarra,
  } = useModalDeCorrecoes();
  const {
    parametrosValidacao,
    dadosDaCapa,
    dadosErpDoFornecedor,
    setCamposAlterados,
    returnDadosRevalidados,
    itemACorrigir,
  } = useValidadorXML();

  const { register, formState } = useForm({
    // resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const [loading, setLoading] = useState(false);

  const handleResponseData = useCallback(
    async (formatedResponse: ResponseDataProps) => {
      const { response, campo, origem, callBack } = formatedResponse;
      if (response.success === false) {
        setExecutando({ ...executando, 100: false });
        toast.warning(response.message);
        return;
      }
      if (typeof response.data === 'object') {
        setDadosItemACorrigir(response.data);
        const codStatus = dadosItemACorrigir.statusDaValidacao.map((status) => {
          return status.codStatus;
        });
        setListaDeStatus(codStatus);
        setExecutando({ ...executando, 100: false });
        setCamposAlterados((prev) => [
          ...prev,
          {
            index: itemACorrigir.index,
            campo,
            origem,
          },
        ]);
        returnDadosRevalidados(response.data);
        if (callBack) {
          await callBack(dadosItemACorrigir.cod_produto);
        }
        setCodigoDeBarra(itemACorrigir.cEAN);
        toast.success(response.message);
      }
    },
    [
      dadosItemACorrigir.cod_produto,
      dadosItemACorrigir.statusDaValidacao,
      executando,
      itemACorrigir.cEAN,
      itemACorrigir.index,
      returnDadosRevalidados,
      setCamposAlterados,
      setCodigoDeBarra,
      setDadosItemACorrigir,
      setExecutando,
      setListaDeStatus,
    ],
  );

  const listaGtinsPorPlu = useCallback(
    async (cod_produto: string) => {
      const { data } = await api.post('/busca-gtins-por-plu', {
        cod_produto,
      });
      if (data.message && data.message === 'Success') {
        setListaDeGtins(data.data);
      }
    },
    [setListaDeGtins],
  );

  const handleAtualizaGtin = useCallback(async () => {
    setLoading(true);
    const response = await atualizaCodigoGtin({
      dadosItemACorrigir,
      parametrosValidacao,
      dadosDaCapa,
      dadosErpDoFornecedor,
      codigoDeBarra,
    });

    await handleResponseData({
      response,
      campo: 'nenhum',
      origem: 'nenhum',
      callBack: listaGtinsPorPlu,
    });
    setLoading(false);
  }, [
    codigoDeBarra,
    dadosDaCapa,
    dadosErpDoFornecedor,
    dadosItemACorrigir,
    handleResponseData,
    listaGtinsPorPlu,
    parametrosValidacao,
  ]);

  /* Manter esse código */
  // const handleDeletaGtin = useCallback(
  //   async (gtin: string) => {
  //     const response = await deletaCodigoGtin({
  //       dadosItemACorrigir,
  //       parametrosValidacao,
  //       dadosDaCapa,
  //       dadosErpDoFornecedor,
  //       codigoDeBarra: gtin,
  //     });

  //     await handleResponseData({
  //       response,
  //       campo: 'nenhum',
  //       origem: 'nenhum',
  //       callBack: listaGtinsPorPlu,
  //     });
  //     setCodigoDeBarra('');
  //   },
  //   [
  //     dadosDaCapa,
  //     dadosErpDoFornecedor,
  //     dadosItemACorrigir,
  //     handleResponseData,
  //     listaGtinsPorPlu,
  //     parametrosValidacao,
  //     setCodigoDeBarra,
  //   ],
  // );

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-3">
          <InputText
            label="Código de Barras do XML"
            maxLength={50}
            placeholder="Cód. Gtin"
            name="cod_gtin"
            register={register}
            disabled={loading}
            value={codigoDeBarra}
            isError={!!formState.errors.cod_gtin}
            onChange={(e: any) => setCodigoDeBarra(e.target.value)}
          />
        </div>
        <div className="col-md-3 button-confirm">
          <ConfirmButton
            disabled={loading}
            onClick={() => handleAtualizaGtin()}
          >
            {loading && (
              <Spinner
                animation="border"
                size="sm"
                style={{ marginRight: '0.4375rem' }}
              />
            )}
            {loading ? 'Adicionando' : 'Adicionar'}
          </ConfirmButton>
        </div>
        <div className="col-md-12 mt-4">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>GTIN</TableCell>
                  <TableCell>Data de Cadastro</TableCell>
                  {/* <TableCell>Excluir</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {listaDeGtins &&
                  listaDeGtins.length > 0 &&
                  listaDeGtins.map((gtin) => {
                    const data = createDateWithoutTimezone(gtin.dta_cadastro).toLocaleDateString(
                      'pt-BR',
                    );
                    return (
                      <TableRow key={gtin.cod_gtin}>
                        <TableCell>{gtin.cod_gtin}</TableCell>
                        <TableCell>
                          {data === '29/12/1899' ? '' : data}
                        </TableCell>
                        {/* Manter esse código */}
                        {/* <TableCell
                          onClick={() => handleDeletaGtin(gtin.cod_gtin)}
                          style={{ cursor: 'pointer' }}
                        >
                          <MdDeleteForever
                            size={20}
                            style={{
                              color: '#e63c3c',
                            }}
                          />
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};
