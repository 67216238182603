import React, { useCallback } from 'react';
import { useModalDeCorrecoes } from '../../ModalDeCorrecoesContext';
import { Container } from './styles';
import { InputAsyncSelect } from '~/components/NovosInputs';
import { useForm } from 'react-hook-form';
import { useValidadorXML } from '~/pages/ValidadorXML/ValidadorContext';

/**
 * @param AlterarCFOP Aqui é efetuada a substituição do CFOP por outro existente no ERP
 */

export const AlterarCFOP: React.FC = () => {
  const {
    dadosItemACorrigir,
    novoCFOP,
    setNovoCFOP,
    loading,
    showData,
    setShowData,
  } = useModalDeCorrecoes();
  const { parametrosValidacao } = useValidadorXML();

  const { register, control, clearErrors, formState } = useForm({
    reValidateMode: 'onBlur',
  });

  const handleCFOPSelected = useCallback(
    (selected: any) => {
      setNovoCFOP(selected);
      setShowData('CFOP');
    },
    [setNovoCFOP, setShowData],
  );

  return (
    <Container className="container">
      <div className="row">
        <div className="col-md-12">
          <InputAsyncSelect
            label="Filtrar CFOP"
            maxLength={50}
            placeholder="Digite o número ou descrição para consulta..."
            name="cfop"
            register={register}
            isError={!!formState.errors.cfop}
            control={control}
            disabled={loading}
            changeSelected={(selected: any) => {
              clearErrors('cfop');
              handleCFOPSelected(selected);
            }}
            api={{
              route: '/busca-cfop',
              method: 'get',
              bodyParams: {
                cod_perfil: parametrosValidacao.cod_perfil,
              },
              fields: ['cfop', 'des_id_ctb'],
              dependsOf: ['cod_perfil'],
              useNanoIdForKey: true,
              searchBeforeFilter: true,
            }}
          />
        </div>
        <div className="col-12">
          <table className="table">
            <thead>
              <tr>
                <th>CFOP XML</th>
                <th>CFOP ERP</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{dadosItemACorrigir.xmlCFOP}</td>
                <td>{dadosItemACorrigir.cfop}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {showData === 'CFOP' && (
          <div className="col-sm-12 mt-4">
            <table className="table">
              <thead>
                <tr>
                  <th>CFOP</th>
                  <th>Descrição</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{novoCFOP.cfop}</td>
                  <td>{novoCFOP.des_id_ctb}</td>
                </tr>
              </tbody>
            </table>
            <table className="table">
              <thead>
                <tr>
                  <th>Cód. ID CTB</th>
                  <th>Perfil Selecionado</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{novoCFOP.cod_id_ctb}</td>
                  <td>{novoCFOP.des_perfil}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Container>
  );
};
